import type { SearchState } from '@faststore/sdk'
import { parseSearchState, SearchProvider, useSession } from '@faststore/sdk'
import type {
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useState } from 'react'
import { RenderClient } from 'src/components/RenderClient'
import Breadcrumb from 'src/components/sections/Breadcrumb'
import ProductGallery from 'src/components/sections/ProductGallery'
import SROnly from 'src/components/ui/SROnly'
import { ITEMS_PER_PAGE } from 'src/constants'
import { useScrollEvent } from 'src/sdk/analytics/hooks/useScrollEvent'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'

import 'src/styles/pages/search.scss'

export type Props = PageProps<
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables
>

const useSearchParams = ({ href }: Location) => {
  const [params, setParams] = useState<SearchState | null>(null)

  useEffect(() => {
    const url = new URL(href)

    setParams(parseSearchState(url))
  }, [href])

  return params
}

function Page(props: Props) {
  const {
    data: { site },
  } = props

  const { locale } = useSession()
  const searchParams = useSearchParams(props.location)
  const title = 'Search Results | BaseStore'

  useEffect(() => {
    window.addEventListener('scroll', useScrollEvent)
    return () => {
      window.removeEventListener('scroll', useScrollEvent)
    }
  }, [])

  if (!searchParams) {
    return null
  }

  return (
    <RenderClient>
      <SearchProvider
        onChange={applySearchState}
        itemsPerPage={ITEMS_PER_PAGE}
        {...searchParams}
      >
        {/* SEO */}
        <GatsbySeo
          language={locale}
          title={title}
          description={site?.siteMetadata?.description ?? ''}
          titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
          openGraph={{
            type: 'website',
            title,
            description: site?.siteMetadata?.description ?? '',
          }}
        />

        <div className="default__pagepdp">
          <SROnly as="h2" text={title} />

          {/*
          WARNING: Do not import or render components from any
          other folder than '../components/sections' in here.

          This is necessary to keep the integration with the CMS
          easy and consistent, enabling the change and reorder
          of elements on this page.

          If needed, wrap your component in a <Section /> component
          (not the HTML tag) before rendering it here.
        */}
          <Breadcrumb name={searchParams.term ?? ''} />
          <ProductGallery
            title="Search Results"
            searchTerm={searchParams.term ?? undefined}
          />
        </div>
      </SearchProvider>
    </RenderClient>
  )
}

export const querySSG = graphql`
  query SearchPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

Page.displayName = 'Page'

export default mark(Page)
